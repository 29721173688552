import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import shuffle from '../helpers/shuffle'

import Transition from '../components/transition/transition'
import Projects from '../components/pages/index/index__projects'


class IndexPage extends React.PureComponent{
  constructor(props){
    super(props)
    // this.posts = shuffle(props.data.wpcontent.posts.nodes)
    this.state = {
      // posts: this.props.data.wpcontent.posts.nodes
      posts: shuffle(this.props.data.wpcontent.posts.nodes)
    }
  }

  render(){
    return(
      <Layout className="page__category" >
        <SEO title="Armon Semadeni Architekten" />
        
        <Transition >
          <main>
            <Projects posts={this.state.posts} filter={false}/>
          </main>
        </Transition>
        
      </Layout>
    )
  }
}

export default IndexPage

export const indexQuery = graphql`
    query indexQuery {
      wpcontent{
        posts(first: 200) {
          nodes {
            title
            slug
            postfields{
              type
              year
              titleExtended
              images {
                id
                mediaItemUrl
                mediaDetails {
                  sizes {
                    sourceUrl
                    height
                    width
                  }
                  height
                  width
                }
              }
            }
          }
        }
      }
      
    }
  `
  // allWordpressPost(limit: 10) {
  //   nodes {
  //     title
  //     path
  //     slug
  //     acf{
  //       type
  //       year
  //       title_extended
  //       images{
  //         id
          
  //       }
  //     }
  //   }
  // }
  // allWordpressPost(limit: 10) {
  //   nodes {
  //     title
  //     path
  //     slug
  //     acf{
  //       type
  //       year
  //       title_extended
  //       images{
  //         id
          
  //       }
  //     }
  //   }
  // }

  // localFile{
  //   childImageSharp {
  //     resolutions {
  //         aspectRatio
  //     }
      
  //     fluid(maxWidth: 500){
  //       ...GatsbyImageSharpFluid_withWebp
  //     }
  //   }
  // }